import React, { useState } from "react";

const Header: React.FC = () => {

  return (
    <div>
      <header className="flex justify-between px-20 py-12 fixed w-full ">
        <div className="text-2xl font-bold cursor-pointer">
          <a href="/">Fabrice</a>
        </div>
        <div>
          <ul className="flex items-center gap-5">
            <li className="cursor-pointer text-[#A0A09F] hover:text-black duration-300">
              <a href="/">Home</a>
            </li>
            <li className="cursor-pointer text-[#A0A09F] hover:text-black duration-300">
              <a href="/about">About</a>
            </li>
            <li className="cursor-pointer text-[#A0A09F] hover:text-black duration-300">
              <a
                href="https://drive.google.com/file/d/1npHyOZcQyMEyYKlI2JTIT5WW51BZ6QZi/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resume
              </a>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
